<template>
    <div>
        <div class="modal fade" id="TypeDetailModal" tabindex="-1" aria-labelledby="TypeDetailModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="TypeDetailModalLabel" class="font-weight-bold">{{ModalTitle}}{{TypeDetailId}}</h4>
                        <a type="button" data-bs-dismiss="modal"
                        aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Kode</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TypeDetailId" v-model="TypeDetailId" class="font-weight-bold" />
                                    <label id="errorTypeDetailId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Nama</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TypeDetailName" v-model="TypeDetailName" class="font-weight-bold" />
                                    <label id="errorTypeDetailName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Group Type</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="GroupTypeId" class="font-weight-bold" :options="GroupTypeData" :value.sync="GroupTypeId" />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Item Group</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="MatGroupId" class="font-weight-bold" :options="MatGroupData" :value.sync="MatGroupId" />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Kode Pajak</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TaxCode" v-model="TaxCode" class="font-weight-bold" />
                                    <label id="errorTaxCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Status Komisi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="CommissionStatus" class="font-weight-bold" :options="CommissionStatusData" :value.sync="CommissionStatus" />
                                    <label id="errorCommissionStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Jatuh Tempo (Hari)</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TermOfPayment" v-model="TermOfPayment" class="font-weight-bold" type="number" />
                                    <label id="errorTermOfPayment" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <br>
                            <label id="errorDuplicate" class="form-error" style="display: none; color: red;"></label>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;">Save</CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close">Close</CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import typeDetailServices from '../Script/TypeDetailServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'TypeDetailForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',
            TypeDetailId: '',
            TypeDetailName: '',
            Status: '',
            StatusData: [],
            GroupTypeId: '',
            GroupTypeData: [],
            MatGroupId: '',
            MatGroupData: [],
            TaxCode: null,
            CommissionStatus: '',
            CommissionStatusData: [ 
                {value: 'A', label: 'Active'},
                {value: 'I', label: 'Inactive'}
            ],
            TermOfPayment:null,
            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        async addClick(){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add Type Detail: '
            this.TypeDetailId = '';
            this.TypeDetailName = '';
            
            this.StatusData = [ 
                {value: 'All', label: 'All'},
                {value: 'Project', label: 'Project'},
                {value: 'Retail', label: 'Retail'},
                {value: 'Gudang', label: 'Gudang'}
            ];
            this.Status = this.StatusData[0].value;
            
            this.GroupTypeData = await typeDetailServices.getGroupType();
            this.GroupTypeId = this.GroupTypeData[0].value;
                 
            this.MatGroupData = await typeDetailServices.getItemGroup();
            this.MatGroupId = this.MatGroupData[0].value;

            this.TaxCode = null;

            this.CommissionStatus = this.CommissionStatusData[0].value;
            this.TermOfPayment = null;

            this.SaveType = 'Add';
            document.getElementById('TypeDetailId').readOnly = false;
            window.$('#TypeDetailModal').modal('show');
        },
        async editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Type Detail: '
                        
            this.TypeDetailId = data.type_detail_id;
            this.TypeDetailName = data.type_detail_name;

            this.StatusData =  [ 
                {value: 'All', label: 'All'},
                {value: 'Inactive', label: 'Inactive'},
                {value: 'Project', label: 'Project'},
                {value: 'Retail', label: 'Retail'},
                {value: 'Gudang', label: 'Gudang'}
            ];
            this.Status = data.active_flag =="N" ? "A":data.active_flag;
            
            this.GroupTypeData = await typeDetailServices.getGroupType();
            this.GroupTypeId = data.group_type_id;
                 
            this.MatGroupData = await typeDetailServices.getItemGroup();
            this.MatGroupId = data.material_group_type_id;
           
            this.TaxCode = data.tax_code;

            this.CommissionStatus = data.commision_status;            
            this.TermOfPayment = data.term_of_payment;

            this.SaveType = 'Edit';
            document.getElementById('TypeDetailId').readOnly = true;
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Type Detail: ';
            }

            window.$('#TypeDetailModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.TypeDetailId == ''){
                this.errorShow('errorTypeDetailId');
            }
            if(this.TypeDetailName == ''){
                this.errorShow('errorTypeDetailName');
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){

                const TypeDetailData = {
                    type_detail_id : this.TypeDetailId,
                    type_detail_name : this.TypeDetailName,
                    active_flag: this.Status,
                    group_type_id : this.GroupTypeId,
                    material_group_type_id : this.MatGroupId,
                    tax_code : this.TaxCode,
                    commision_status : this.CommissionStatus,
                    term_of_payment: this.TermOfPayment
                };

                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : TypeDetailData
                    }

                    typeDetailServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#TypeDetailModal').modal('hide');
                        this.$swal("Info", response.successSave, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.TypeDetailId.toString(),
                        data : TypeDetailData
                    }

                    typeDetailServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#TypeDetailModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    },
}
</script>
